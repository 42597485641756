html {
  color-scheme: dark;
  background-color: black;
  scroll-behavior: smooth !important;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

img, svg, video {
  max-width: 100%;
  display: block;
}
